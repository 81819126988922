import Razorpay from "razorpay";
import React from "react";

const Masterfeature: React.FC = () => {
  const handleBookNow2 = () => {
    window.location.href = "https://rzp.io/rzp/6kV60iF";
  };
  const handleBookNow4 = () => {
    window.location.href = "https://rzp.io/rzp/oneweekunlimited";
  };

  const loadRazorpayScript = (src: string) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleBookNow3 = () => {
    window.location.href = "https://rzp.io/rzp/WYPXJsZt";
  };

  const handleBookNow = async () => {
    const isScriptLoaded = await loadRazorpayScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );
    if (!isScriptLoaded) {
      alert("Failed to load payment gateway. Please try again.");
      return;
    }

    const response = await fetch("http://localhost:8080/api/create-order", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: 45 }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Order created:", data);
    } else {
      console.error("Error creating order:", await response.text());
    }

    const { orderId } = await response.json();
    if (!orderId) {
      alert("Failed to create Razorpay order. Please try again.");
      console.log("Failed to create Razorpay order. Please try again.");
      throw new Error("Order ID not found in response");
    }

    const options = {
      key: "rzp_live_5ru2zEaMJjJWQ5",
      amount: 4500,
      currency: "INR",
      name: "CrackIIT",
      description: "CrackIIT Master Plan",
      image: "/logo.png",
      handler: function (response: any) {
        console.log("Payment successful!", response);
        alert(
          "Payment Successful! Transaction ID: " + response.razorpay_payment_id
        );
      },
      prefill: {
        name: "",
        email: "",
        contact: "",
      },
      theme: {
        color: "#8a2be2",
      },
    };

    const paymentObject = new (window as any).Razorpay(options);
    paymentObject.open();
  };

  return (
    <div className="relative min-h-screen text-white">
      <div className="video fixed top-0 left-0 w-full h-full -z-20">
        <video autoPlay loop muted className="w-full h-full object-cover">
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div className="container mx-auto px-4 py-16 relative z-20 flex justify-center items-center min-h-screen">
        <div className="flex flex-wrap gap-6">
          {/* First Card */}
          <div
            className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white w-full max-w-sm"
            style={{
              borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
              borderWidth: "6px",
              borderStyle: "solid",
            }}
          >
            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              50% OFF! REPUBLIC DAY OFFER
            </h3>
            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              Ask Doubts from an IITian at the price of a CHIPS packet
            </h3>
            <p className="text-sm mb-6">
              Access a personalized mentorship program tailored to your specific
              needs. Learn directly from IITians with expert guidance.
            </p>
            <div className="flex justify-between items-center mb-4">
              <span className="text-3xl font-bold">
                <del className="text-red-500">₹10</del> ₹5
              </span>
              <span className="text-sm">/1 DOUBT</span>
            </div>
            <ul className="space-y-2 mb-6 text-sm">
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Ask doubt from an IITian at the price of a CHIPS packet
              </li>
            </ul>
            <button
              onClick={handleBookNow3}
              className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
            >
              GET SOLUTION FROM IITian MENTOR @₹5
            </button>
          </div>

          {/* Second Card */}
          <div
            className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white w-full max-w-sm"
            style={{
              borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
              borderWidth: "6px",
              borderStyle: "solid",
            }}
          >
            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              20% OFF! REPUBLIC DAY OFFER
            </h3>

            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              CrackIIT Master Plan
            </h3>

            <p className="text-sm mb-6">
              Access our exclusive IITian mentorship program designed for
              serious JEE aspirants. Learn from the best IITians and NITians and
              achieve your dream!
            </p>

            <div className="flex justify-between items-center mb-4">
              <span className="text-3xl font-bold">
                <del className="text-red-500">₹45</del> ₹39
              </span>
              <span className="text-sm">/2 days</span>
            </div>

            <ul className="space-y-2 mb-6 text-sm">
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Ask doubts from IITian mentors
              </li>
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                20 doubts can be asked
              </li>
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Solutions by IITian mentors
              </li>

              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                2 days validity
              </li>
            </ul>
            <button
              onClick={handleBookNow2}
              className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
            >
              Click to connect with IITian mentor @₹39
            </button>
          </div>

          {/* Third Card (New Card) */}
          <div
            className="relative bg-gradient-to-br from-purple-800 to-purple-600 p-8 rounded-3xl shadow-lg text-white w-full max-w-sm"
            style={{
              borderImage: "linear-gradient(to right, #8a2be2, #4b0082) 1",
              borderWidth: "6px",
              borderStyle: "solid",
            }}
          >
            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              50% OFF! REPUBLIC DAY
            </h3>

            <h3 className="text-2xl font-bold mb-4 text-purple-300">
              IIT-JEE Fast Track Doubt Solving
            </h3>

            <p className="text-sm mb-6">
              Fast-track your IIT-JEE preparation with tailored Doubt Solving from top IITians. Get expert guidance & learn their way of solving the questions!
            </p>

            <div className="flex justify-between items-center mb-4">
              <span className="text-3xl font-bold">
                <del className="text-red-500">₹300</del> ₹149
              </span>
              <span className="text-sm">/1 week</span>
            </div>

            <ul className="space-y-2 mb-6 text-sm">
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Ask doubts from IITian mentors

              </li>
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Unlimited doubts can be asked
              </li>
              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Solutions by IITian mentors

              </li>

              <li className="flex items-center">
                <svg
                  className="w-5 h-5 text-green-500 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                1 week 

              </li>
            </ul>
            <button
              onClick={handleBookNow4}
              className="block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded text-center transition duration-300"
            >
              Connect with Mentor @₹149
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Masterfeature;
