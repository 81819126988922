import React from "react";

const TeacherProfiles: React.FC = () => {
  const teachers = [
    {
      name: "Palak Kothari",
      college: "IIT GUWAHATI (B.Tech)",
      img: "/palak.png",
    },
    {
      name: "ABHINAV ADITYA",
      college: "IIT DHANBAD",
      img: "/abhinav.png",
    },
    {
      name: "Prashant Shivhare",
      college: "IIT BOMBAY (B.Tech)",
      img: "/prashant.png",
    },
    {
      name: "Nayan Patidar",
      college: "IIT JODHPUR (B.Tech)",
      img: "/nay.png",
    },
    {
      name: "Ajaypal Singh",
      college: "IIT ROORKEE",
      img: "/ajay.png",
    },
    {
      name: "Vikas Yadav",
      college: "IIT JODHPUR",
      img: "/vikas.png",
    },
    {
      name: "Priyanshu Kumar",
      college: "IIT BOMBAY (M sc)",
      img: "/priy-kum.png",
    },
    {
      name: "PRIYANSHU YADAV",
      college: "IIT ROORKEE (B.Tech)",
      img: "/pri.png",
    },

    {
      name: "Arham Tabish",
      college: "IIT BHUBANESHWAR (B.Tech)",
      img: "/arham.png",
    },
    {
      name: "Manav",
      college: "IIT KHARAGPUR (B.Tech)",
      img: "/manav.png",
    },
    {
      name: "Vinayak",
      college: "BITS PILANI (B.Tech)",
      img: "/vin.png",
    },
    {
      name: "Pramiti",
      college: "IIT GUWAHATI (B.Tech)",
      img: "/iitg.png",
    },
    {
      name: "Abhishek Singh",
      college: "IIT JODHPUR (B.Tech)",
      img: "/abhishek.jpg",
    },
    {
      name: "Shashwat",
      college: "NIT Jaipur (B.Tech)",
      img: "/sh.png",
    },
    
  ];

  const handleMentorClick = () => {
    // Redirect to click to connect URL
    window.location.href = "https://rzp.io/rzp/yM4r7eTa";
  };

  return (
    <div className="min-h-screen py-12 px-6 relative">
      {/* Background Video */}
      <div className="fixed top-0 left-0 w-full h-full -z-10">
        <video autoPlay loop muted className="w-full h-full object-cover">
          <source src="/herosection.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Header Section */}
      <div className="flex flex-col lg:flex-row items-center justify-center gap-8 mb-1 header-section">
        {/* For Mobile */}
        <div className="flex flex-col gap-4 lg:hidden">

        <h1 className="text-center text-2xl font-extrabold uppercase text-white">
            Your Mentors
          </h1>

          <button
            onClick={handleMentorClick}
            className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
          >
            CLICK TO CONNECT WITH IITian
          </button>
          
          <div
            className="rounded-lg shadow-md text-white p-4  bg-opacity-75 text-card"
            style={{
              maxWidth: "300px",
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <p>
              <strong>- Discount offer valid for REPUBLIC DAY TILL 31 jan only! Hurry now!! </strong> 
            </p>
            <p>
              <strong>- Ask doubts from IITian Mentors @ ₹99 Only </strong> (1 hr session)
            </p>
            <p>
              <strong>- Mentorship from IITian Mentors @ ₹99 Only </strong> (1 hr session)
            </p>
            <p>
              <strong>- Discount applied when you proceed for payment </strong> 
            </p>
          </div>
        </div>

        {/* For Desktop */}
        
        <div className="hidden lg:flex flex-col items-center gap-4">
        <div className="spacer" style={{ height: '0.5rem' }}></div>  
          <h1 className="text-center text-4xl font-extrabold uppercase text-white">
            Your Mentors
          </h1>
          <button
            onClick={handleMentorClick}
            className="bg-gradient-to-r from-purple-600 to-purple-800 text-white px-6 py-3 rounded-lg shadow-md hover:from-purple-800 hover:to-purple-600 transition duration-300 transform hover:scale-105"
          >
            CLICK TO CONNECT WITH IITian
          </button>
          <div
            className="rounded-lg shadow-md text-white p-4  bg-opacity-75 text-card"
            style={{
              maxWidth: "300px",
              textAlign: "center",
            }}
          >
            <p>
              <strong>- HURRY NOW!! 20% off REPUBLIC DAY OFFER (CASHBACK) </strong>
            </p>
            <p>
              <strong>- Ask doubts from IITian Mentors @ ₹99 Only </strong> (5 hrs, 20 doubts max)
            </p>
            <p>
              <strong>- Mentorship from IITian Mentors @ ₹99 Only </strong> (1 hour session)
            </p>
            
          </div>
        </div>
      </div>

      {/* Mentors Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {teachers.map((teacher, index) => (
          <div
            key={index}
            className="p-6 rounded-lg shadow-lg hover:shadow-2xl transform hover:scale-105 transition duration-300 cursor-pointer bg-opacity-80"
            onClick={handleMentorClick} // Redirects to the URL on click
          >
            <div className="flex items-center space-x-4 mb-4">
              <img
                src={teacher.img}
                alt={teacher.name}
                className="w-32 h-32 rounded-full border-4 border-purple-500"
              />
            </div>
            <div>
              <h2 className="text-xl font-extrabold text-purple-300">{teacher.name}</h2>
              <h2 className="text-lg text-white">{teacher.college}</h2>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeacherProfiles;